const PlayIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.1493 15.9994L9.33268 27.9494C8.16602 28.6494 6.66602 27.8161 6.66602 26.4494V13.1161C6.66602 7.29941 12.9493 3.66608 17.9993 6.56608L25.6494 10.9661L29.1327 12.9661C30.2827 13.6494 30.2993 15.3161 29.1493 15.9994Z"
      fill="white"
    />
    <path
      d="M30.152 25.7658L23.402 29.6658L16.6686 33.5491C14.252 34.9324 11.5186 34.6491 9.5353 33.2491C8.56863 32.5824 8.6853 31.0991 9.70196 30.4991L30.8853 17.7991C31.8853 17.1991 33.202 17.7658 33.3853 18.9158C33.802 21.4991 32.7353 24.2824 30.152 25.7658Z"
      fill="white"
    />
  </svg>
);

export default PlayIcon;
