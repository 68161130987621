export const ThrashIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 3.98665C11.78 3.76665 9.54667 3.65332 7.32 3.65332C6 3.65332 4.68 3.71999 3.36 3.85332L2 3.98665"
      stroke="#9B8FA1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.66699 3.31301L5.81366 2.43967C5.92033 1.80634 6.00033 1.33301 7.12699 1.33301H8.87366C10.0003 1.33301 10.087 1.83301 10.187 2.44634L10.3337 3.31301"
      stroke="#9B8FA1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5669 6.09375L12.1336 12.8071C12.0603 13.8537 12.0003 14.6671 10.1403 14.6671H5.86026C4.00026 14.6671 3.94026 13.8537 3.86693 12.8071L3.43359 6.09375"
      stroke="#9B8FA1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.88672 11H9.10672" stroke="#9B8FA1" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6.33301 8.33301H9.66634"
      stroke="#9B8FA1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ThrashIconUpdated = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 3.98665C11.78 3.76665 9.54667 3.65332 7.32 3.65332C6 3.65332 4.68 3.71999 3.36 3.85332L2 3.98665"
      stroke="#BFB6C3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.66602 3.31301L5.81268 2.43967C5.91935 1.80634 5.99935 1.33301 7.12602 1.33301H8.87268C9.99935 1.33301 10.086 1.83301 10.186 2.44634L10.3327 3.31301"
      stroke="#BFB6C3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5669 6.09375L12.1336 12.8071C12.0603 13.8537 12.0003 14.6671 10.1403 14.6671H5.86026C4.00026 14.6671 3.94026 13.8537 3.86693 12.8071L3.43359 6.09375"
      stroke="#BFB6C3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M6.88672 11H9.10672" stroke="#BFB6C3" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M6.33398 8.33301H9.66732"
      stroke="#BFB6C3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
